import { useTranslation } from 'next-i18next';

import { ContinentSuggestionData } from '../../types/homePageTypes';
import useMediaQuery from '../../utils/useMediaQuery';
import usePerfectWidth from '../../utils/usePerfectWidth';
import { ContinentCard } from '../atoms/ContinentCard';
import { Heading } from '../atoms/Heading';
import { SideScrollWithButtons } from '../molecules/side-scroll/SideScrollWithButtons';

interface IContinentListProps {
  continents: ContinentSuggestionData[];
}

const ContinentList = ({ continents }: IContinentListProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('md');
  const width = usePerfectWidth(
    continents[0]?.suggestion?.name,
    continents[1]?.suggestion?.name,
    isMobile ? 200 : 350,
    isMobile ? 400 : 500,
    isMobile ? 288 : 484
  );
  return (
    <SideScrollWithButtons
      //offset = width + gap between two children
      offset={width + 32}
      item={
        <Heading headingLevel="h2" size="2xl">
          {t('common:continent-list')}
        </Heading>
      }>
      {continents.map(continent => (
        <ContinentCard
          id={continent.suggestion.name}
          key={continent.suggestion.name}
          continent={continent}
          width={width}
        />
      ))}
    </SideScrollWithButtons>
  );
};

export default ContinentList;
