import { motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import clsxm from '../../lib/clsxm';
import { FilterValue } from '../../types';
import { Size } from '../../types/enums';
import { SportGridElement } from '../../types/homePageTypes';
import useMediaQuery from '../../utils/useMediaQuery';
import AhotuLink from '../atoms/AhotuLink';
import AhotwoPill from '../atoms/AhotwoPill';
import BackgroundImage from '../atoms/BackgroundImage';
import { Heading } from '../atoms/Heading';

interface ISportsGridProps {
  categories: SportGridElement[];
}

const SportsGrid = ({ categories }: ISportsGridProps) => {
  return (
    <>
      <div className={clsxm('grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3')}>
        {categories.slice(0, 6).map(sport => {
          return (
            <LargeGridBox key={sport.name}>
              <GridContent category={sport} />
            </LargeGridBox>
          );
        })}
      </div>
    </>
  );
};

const LargeGridBox = ({ children }) => {
  return <div className="group h-[100vw] md:h-[50vw] lg:h-[33vw] bg-fit bg-center">{children}</div>;
};

interface IGridContentProps {
  category: SportGridElement;
}

const GridContent = ({ category }: IGridContentProps) => {
  const [hover, setHover] = useState(false);
  const isMobile = useMediaQuery('md');

  const animateHeadline = {
    open: { marginBottom: '3.5rem' },
    closed: { marginBottom: 0 }
  };

  const animateChips = {
    open: custom => ({
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: custom / 30
      }
    }),
    closed: { scale: 1, opacity: 0 }
  };

  const toggleHover = (isHover: boolean) => () => {
    setHover(isHover);
  };

  return (
    <BackgroundImage
      image={category.image}
      sizes="(max-width: 768px) 100vw, (max-width: 1024px) 50vw, 33vw"
      alt={`Image of ${category.name}`}>
      <div
        onMouseEnter={toggleHover(true)}
        onMouseLeave={toggleHover(false)}
        className="flex flex-col justify-end items-center h-full pb-8 lg:pb-6 xl:pb-8 space-y-2 bg-gradient-to-t from-[#000000]/100 to-[#000000]/10 md:from-[#000000]/60 md:hover:from-[#000000]/90 md:to-transparent text-center">
        {isMobile ? (
          <GridTitle category={category} />
        ) : (
          <motion.div animate={hover ? 'open' : 'closed'} variants={animateHeadline}>
            <GridTitle category={category} />
          </motion.div>
        )}
        <div className="flex group-hover:flex flex-row flex-wrap justify-center w-[90%] sm:w-[80%] lg:w-[94%] xl:w-[90%] gap-4 lg:gap-3 xl:gap-4 md:hidden">
          {category.distances.map((distance, i) => {
            if (isMobile) {
              return <GridPils key={i} distance={distance} />;
            }

            return (
              <motion.div animate={hover ? 'open' : 'closed'} variants={animateChips} custom={i} key={i}>
                <GridPils distance={distance} />
              </motion.div>
            );
          })}
        </div>
      </div>
    </BackgroundImage>
  );
};

interface IGridTitle {
  category: SportGridElement;
}

const GridTitle = ({ category }: IGridTitle) => {
  const { t } = useTranslation();

  return (
    <>
      <AhotuLink href={category.url}>
        <Heading headingLevel="h2" className="cursor-pointer hover:underline" size="3xl" color="white">
          {category.name}
        </Heading>
      </AhotuLink>
      <span className="text-green-70 text-base font-bold text-center pb-8 md:pb-0 ">
        {category.docCount.toLocaleString()} {t('common:event', { count: Math.ceil(category.docCount) })}
      </span>
    </>
  );
};

interface IGridPils {
  distance: Pick<FilterValue, 'order' | 'name' | 'url'>;
}
const GridPils = ({ distance }: IGridPils) => {
  return (
    <AhotwoPill isButton={true} url={distance.url} size={Size.md} className="py-1">
      {distance.name}
    </AhotwoPill>
  );
};

export default SportsGrid;
