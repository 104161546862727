import { SpotlightsType } from '../../../types/homePageTypes';
import Spotlight from '../../atoms/homepage/Spotlight';

interface ISpotlightProps {
  spotlights: SpotlightsType[];
}

const Spotlights = ({ spotlights }: ISpotlightProps) => {
  return (
    <div className="flex flex-col md:flex-row justify-between space-y-12 md:space-x-12 md:space-y-0">
      {spotlights.map(spotlight => (
        <Spotlight key={spotlight.eventName} spotlight={spotlight} />
      ))}
    </div>
  );
};

export default Spotlights;
