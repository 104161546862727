import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { memo } from 'react';

import { getLocalizedProperty } from '../../lib/localization';
import { AhotuSection } from '../../templates/AhotuSection';
import { HeaderType } from '../../types/homePageTypes';
import { routes } from '../../utils/routes';
import { Heading } from '../atoms/Heading';
import { LinkButton } from '../atoms/LinkButton';
import { AhotwoArrowRightIcon } from '../icons/arrow';

interface IHomePageHeaderProps {
  header: HeaderType;
}

const HomepageHeader = ({ header }: IHomePageHeaderProps) => {
  const { locale } = useRouter();
  return (
    <AhotuSection id="header">
      <div className="flex flex-col justify-center items-center">
        <HomepageHeaderBackgroundImage className="h-full" image={header.image}>
          <div className="flex flex-col h-full justify-center items-center bg-fullblack bg-opacity-30">
            <Heading
              headingLevel="h1"
              className="text-center mx-6 pb-4 z-1 text-4xl lg:text-5xl 4xl:text-6xl"
              color="white">
              {getLocalizedProperty(header.slogan, locale)}
            </Heading>
            <LinkButton
              variant="white"
              text={getLocalizedProperty(header.button.text, locale)}
              type="button"
              className="w-80"
              iconRight={AhotwoArrowRightIcon}
              href={getLocalizedProperty(routes.calendar, locale)}
            />
          </div>
        </HomepageHeaderBackgroundImage>
      </div>
    </AhotuSection>
  );
};

interface IHomepageHeaderBackgroundImage {
  children: React.ReactNode;
  image: string;
  className?: string;
}

const HomepageHeaderBackgroundImage = ({ children, image, className }: IHomepageHeaderBackgroundImage) => {
  return (
    <div className={clsx('ah-hero-image', className)}>
      <Image
        priority
        layout="fill"
        objectFit="cover"
        sizes="(min-width: 768px) 100vw, 200vw"
        src={image}
        alt="Adventure image, travel image, discover the world through endurance"
      />
      <div className="w-full h-full absolute bottom-0">{children}</div>
    </div>
  );
};

export default memo(HomepageHeader);
