import { useTranslation } from 'next-i18next';

import { Paragraph } from '../../components/atoms/Paragraph';
import clsxm from '../../lib/clsxm';
import { AhotuSection } from '../../templates/AhotuSection';
import { USPData } from '../../types/homePageTypes';
import { FlagIcon, TrophyIcon, WorldIcon } from '../icons/icons';

interface IUSPBarProps {
  data: USPData;
}
const USPBar = ({ data }: IUSPBarProps) => {
  const { t } = useTranslation();

  const usps = [
    { title: t('home-page:usp-1', { count: data.sports }), icon: FlagIcon },
    { title: t('home-page:usp-2'), icon: TrophyIcon },
    { title: t('home-page:usp-3', { count: data.countries }), icon: WorldIcon }
  ];

  return (
    <AhotuSection id="usp" bgColor="lightgreen">
      <div
        className={clsxm(
          'flex flex-col justify-center items-center space-y-8  py-10 lg:py-0 lg:space-y-0 lg:flex-row lg:h-24 lg:justify-around'
        )}>
        {usps.map(usp => (
          <div key={usp.title} className="flex flex-col items-center lg:space-x-4 space-y-2 lg:flex-row lg:space-y-0">
            <div className="flex justify-center h-8 w-8">
              <usp.icon className="h-8 w-8" />
            </div>
            <Paragraph className="m-0 text-center">{usp.title}</Paragraph>
          </div>
        ))}
      </div>
    </AhotuSection>
  );
};

export default USPBar;
