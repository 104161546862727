import { useRouter } from 'next/router';

import { getLocalizedProperty } from '../../../lib/localization';
import { TranslatedText } from '../../../types/GeneralTypes';
import { Paragraph } from '../Paragraph';

interface IInfoText {
  text: TranslatedText;
}

const InfoText = ({ text }: IInfoText) => {
  const { locale } = useRouter();
  return (
    <div className="flex justify-center">
      <Paragraph className="w-3/4 md:w-2/3 text-center text-2xl text-softblack mb-0">
        {getLocalizedProperty(text, locale)}
      </Paragraph>
    </div>
  );
};

export default InfoText;
